import { Tabs, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Office from './Office';
import User from './User';
import Broker from './Broker';

const { Title } = Typography;

const Admin = () => {
  return (
    <Content className='bg-white p-4 m-2 flex flex-col h-[98%]'>
      <Title level={5} className='ml-4'>Admin</Title>
      <Tabs type='card' className='mt-4 mt-4 flex-grow overflow-hidden' items={[
        { 
          label: `Brokerage`,
          key: '1',
          children: <Broker />
        },
        { 
          label: `Offices`,
          key: '2',
          children: <Office />
        },
        { 
          label: `Users`,
          key: '3',
          children: <div className='h-full overflow-y-auto'><User /></div>
        }
      ]}>
      </Tabs>
    </Content>
  );
};

export default Admin;
