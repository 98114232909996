import { gql } from '@apollo/client';

export const CREATE_COMMENT_MUTATION = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(createCommentInput: $input) {
      id
      authorId
      postId
      body
      createdAt
    }
  }
`;