import apolloClient from '@config/apolloClient';
import { CREATE_POST_MUTATION } from '@mutations/post/create.gpl';
import { DELETE_POST_MUTATION } from '@mutations/post/delete.gpl';
import { UPDATE_POST_MUTATION } from '@mutations/post/update.gpl';
import { POST_QUERY } from '@queries/post.gpl';
import { POSTS_QUERY } from '@queries/posts.gpl';

export const findPostRequest = async (id: number) => {
	const { data, errors } = await apolloClient.query({
		query: POST_QUERY,
    variables: { id }
	});

	if (errors) return { errors };

	return { response: data.post };
};

export const findPostsRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.query({
		query: POSTS_QUERY,
    variables: { filters: { ...payload }}
	});

	if (errors) return { errors };

	return { response: data.posts };
};

export const createPostRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: CREATE_POST_MUTATION,
		variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { response: data.createPost };
};

export const updatePostRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: UPDATE_POST_MUTATION,
		variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { response: data.updatePost };
};

export const deletePostRequest = async (id: number) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: DELETE_POST_MUTATION,
    variables: { id }
	});

	if (errors) return { errors };

	return { response: data.removePost };
};