export enum Role {
  BROKER = 'Broker',
  AGENT = 'Agent',
  AUDITOR = 'Auditor',
}

export enum Social {
  INSTAGRAM = 'INSTAGRAM', 
  TWITTER = 'TWITTER', 
  FACEBOOK = 'FACEBOOK', 
  YOUTUBE = 'YOUTUBE', 
  LINKEDIN = 'LINKEDIN', 
  TIKTOK = 'TIKTOK', 
  TWITCH = 'TWITCH', 
}

export enum StorageKeys {
  USER = 'user',
  TOKEN = 'token',
  REFRESH = 'refresh',
  POST = 'post',
  CHAT = 'chat',
}

export enum NotificationType {
  POST = 'Post',
  MESSAGE = 'Message',
  COMMENT = 'Comment',
}

export enum States {
  Alabama = 'AL',
  Alaska = 'AK',
  Arizona = 'AZ',
  Arkansas = 'AR',
  California = 'CA',
  Colorado = 'CO',
  Connecticut = 'CT',
  Delaware = 'DE',
  Florida = 'FL',
  Georgia = 'GA',
  Hawaii = 'HI',
  Idaho = 'ID',
  Illinois = 'IL',
  Indiana = 'IN',
  Iowa = 'IA',
  Kansas = 'KS',
  Kentucky = 'KY',
  Louisiana = 'LA',
  Maine = 'ME',
  Maryland = 'MD',
  Massachusetts = 'MA',
  Michigan = 'MI',
  Minnesota = 'MN',
  Mississippi = 'MS',
  Missouri = 'MO',
  Montana = 'MT',
  Nebraska = 'NE',
  Nevada = 'NV',
  NewHampshire = 'NH',
  NewJersey = 'NJ',
  NewMexico = 'NM',
  NewYork = 'NY',
  NorthCarolina = 'NC',
  NorthDakota = 'ND',
  Ohio = 'OH',
  Oklahoma = 'OK',
  Oregon = 'OR',
  Pennsylvania = 'PA',
  RhodeIsland = 'RI',
  SouthCarolina = 'SC',
  SouthDakota = 'SD',
  Tennessee = 'TN',
  Texas = 'TX',
  Utah = 'UT',
  Vermont = 'VT',
  Virginia = 'VA',
  Washington = 'WA',
  WestVirginia = 'WV',
  Wisconsin = 'WI',
  Wyoming = 'WY',
}
