import { gql } from '@apollo/client';

export const UPDATE_POST_MUTATION = gql`
  mutation UpdatePost($input: UpdatePostInput!) {
    updatePost(updatePostInput: $input) {
      id
      authorId
      body
      attachments
      createdAt
    }
  }
`;