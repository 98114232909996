/* eslint-disable react-hooks/exhaustive-deps */
import { UserOutlined } from '@ant-design/icons';
import { INotification } from '@interfaces/notification.interface';
import { findNotificationsRequest, markAllAsReadRequest, markAsReadRequest } from '@shared/services/notification.service';
import { NotificationType } from '@shared/utils/enums';
import { formatDateNotification } from '@shared/utils/functions';
import { Avatar, Badge, Popover } from 'antd';
import { useEffect, useState } from 'react';
import { BiSolidCommentDetail } from 'react-icons/bi';
import { FaBell, FaCommentDots, FaDotCircle } from 'react-icons/fa';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const NotificationCardMini = ({notification, onSelect}: any) => {
  const navigate = useNavigate();
  
  const _onNotificationClick = () => {
    if(!notification.read) markAsReadRequest(notification.id);
    onSelect(notification.id);

    if(notification.type === NotificationType.COMMENT) {
      navigate('/board', { state: { postId: notification.data.post }});
    } else {
      navigate('/chat', { state: { chatroomId: notification.data.chatroom }});
    }
  };

  return (
    <div className='flex text-xs cursor-pointer hover:bg-slate-200 pr-4 py-1 items-center justifiy-center rounded-xl' onClick={_onNotificationClick}>
      <div className='relative'>
        <Avatar size={45} src={notification.creator.avatar} icon={<UserOutlined />} className='m-2'/>
        {notification.type === NotificationType.COMMENT ?
          <Avatar size={24} icon={<BiSolidCommentDetail />} className='bottom-[4px] right-0 absolute z-10 bg-lime-500'/> : 
          <Avatar size={24} icon={<FaCommentDots />} className='bottom-[4px] right-0 absolute z-10 bg-sky-500'/>
        }
      </div>
      <div className='ml-2 mt-[-10px] flex flex-col w-full h-[40px]'>
        {notification.type === NotificationType.COMMENT ?
          <p><b>{notification.creator.fullName}</b> commented on your post</p> : 
          <p>New private message from <b>{notification.creator.fullName}</b></p>
        }
        <div className={`self-end mb-2 mt-[-10px] italic ${notification.read ? '' : 'text-distinctive-gold'}`}>{formatDateNotification(new Date(notification.createdAt))}</div>
      </div>
      {notification.read ? 
        <IoCheckmarkDoneSharp size={15} className='ml-4'/> : 
        <FaDotCircle size={15} className='ml-4 text-distinctive-gold'/>
      }
    </div>
  );
};

const NotificationList = ({ notifications, onSelect, onSelectAll, showButton }: any) => {
  const navigate = useNavigate();
  
  const _onMarkAllRead = () => {
    markAllAsReadRequest();
    onSelectAll();
  };

  const _onGoToNotifications = () => {
    navigate('/notification');
  };

  return (
    <div className='w-[270px] flex flex-col'>
      <div className='w-full flex justify-between items-center'>
        <p className='font-bold text-base ml-1'>Notifications</p>
        {showButton && 
          <p className='flex items-center font-bold text-xs ml-1 mr-2 cursor-pointer text-grey hover:text-distinctive-gold' onClick={_onMarkAllRead}>
            <IoCheckmarkDoneSharp size={15} className='mr-1'/>Mark All Read
          </p>
        }
      </div>
      {notifications.length === 0 && <div className='self-center'>No notifications to show</div>}
      <div className='flex flex-col'>
        {notifications.map((notification: any) => 
          <NotificationCardMini notification={notification} onSelect={onSelect}/>
        )}
      </div>
      {notifications.length > 0 &&<div className='self-center mt-2 hover:text-distinctive-gold cursor-pointer font-bold text-xs' onClick={_onGoToNotifications}>Manage Notifications</div>}
    </div>
  );
};

const NotificationMenu = () => {
  const [unread, setUnread] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  useEffect(() => {
    window.addEventListener('new_notification', async (e: any) => {
      fetchNotifications();
    });

    fetchNotifications();
    return () => { window.removeEventListener('new_notification', () => {}) };
  }, []);

  const fetchNotifications = () => {
    findNotificationsRequest()
      .then((res) => {
        setNotifications(res.response.notifications);
        setUnread(res.response.count);
      });
  };
  
  const _onNotificationRead = (id: number) => {
    const notification = notifications.find((n) => n.id === id);

    if(!notification?.read) {
      const updatedNotifications = notifications.map((n: INotification) => n.id === id ? { ...n, read: true } : n);
      setNotifications(updatedNotifications);
      setUnread(unread - 1);
    }

    setShowMenu(false);
  };

  const _onMarkAllRead = () => {
    const updatedNotifications = notifications.map((n: INotification) => { return { ...n, read: true }});
    setNotifications(updatedNotifications);
    setUnread(0);
  };

  return (
    <Popover 
      open={showMenu}
      onOpenChange={(visible) => setShowMenu(visible)}
      placement='bottomLeft' 
      className='mt-[-8px] mr-2 cursor-pointer hover:text-distinctive-gold text-distinctive-text' 
      content={<NotificationList notifications={notifications} onSelect={_onNotificationRead} onSelectAll={_onMarkAllRead} showButton={unread > 0}/>}
    >
      <Badge count={unread} size='small' >
        <FaBell size={20} onClick={() => setShowMenu(true)}/>
      </Badge>
    </Popover>
  );
};

export default NotificationMenu;