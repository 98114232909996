import apolloClient from '@config/apolloClient';
import { IBrokerage } from '@interfaces/brokerage.interface';
import { UPDATE_BROKERAGE_MUTATION } from '@mutations/brokerage/update.gpl';
import { BROKERAGE_QUERY } from '@queries/brokerage.gpl';

export const findBrokerageRequest = async () => {
	const { data, errors } = await apolloClient.query({
		query: BROKERAGE_QUERY
	});

	if (errors) return { errors };

	return { response: data.brokerage };
};

export const updateBrokerageRequest = async (payload: IBrokerage) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: UPDATE_BROKERAGE_MUTATION,
		variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return data.updateUser;
};