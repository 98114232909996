import { gql } from '@apollo/client';

export const UPDATE_BROKERAGE_MUTATION = gql`
  mutation UpdateBrokerage($input: UpdateBrokerageInput!) {
    updateBrokerage(updateBrokerageInput: $input) {
      name
      phone
      fax
    }
  }
`;