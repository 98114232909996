import apolloClient from '@config/apolloClient';
import { CREATE_MESSAGE_MUTATION } from '@mutations/message/create.gpl';
import { MESSAGES_QUERY } from '@queries/messages.gpl';

export const findMessagesRequest =  async (id: number) => {
	const { data, errors } = await apolloClient.query({
		query: MESSAGES_QUERY,
    variables: { id },
		fetchPolicy: 'network-only'
	});

	if (errors) return { errors };

	return { response: data.messages };
};

export const createMessageRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: CREATE_MESSAGE_MUTATION,
		variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { response: data.createMessage };
};