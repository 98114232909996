import { gql } from '@apollo/client';

export const BROKERAGE_QUERY = gql`
  query Brokerage {
    brokerage {
      name
      phone
      fax
    }
  }
`;
