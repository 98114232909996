/* eslint-disable react-hooks/exhaustive-deps */
import { IBrokerage } from '@interfaces/brokerage.interface';
import { findBrokerageRequest, updateBrokerageRequest } from '@shared/services/brokerage.service';
import { Divider, Input, message, Typography } from 'antd';
import MaskedInput from 'antd-mask-input';
import { Content } from 'antd/es/layout/layout';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

const { Title } = Typography;

const Broker = () => {
  const [formData, setFormData] = useState<IBrokerage>({});
  const [messageApi, contextHolder] = message.useMessage();
  
  useEffect(() => {
    const fetchData = async () => {
      const result = await findBrokerageRequest();
      setFormData({ ...result.response });
    };

    fetchData();
  }, []); 

  const saveChanges = useCallback(
    debounce((values) => {
      delete values.__typename;
      
      updateBrokerageRequest(values)
        .then(() => {
          messageApi.open({ type: 'success', content: 'Brokerage information updated correctly' });
        })
    }, 1000), []
  );

  const _onChange = (name: string, value: any) => {
    setFormData({ ...formData, [name]: value });
    saveChanges({ ...formData, [name]: value });
  };

  return (
    <>
      {contextHolder}
      <Content className='px-4 py-8 flex-grow'>
        <Title level={5} className='mt-[-20px] mb-[-20px]'>General Information</Title>
        <Divider className='mt-0' />
        <div className='w-full grid grid-cols-12 grid-rows-2 gap-4 mb-8'>
          <div className='col-span-5'>
            <div className='text-xs'>Brokerage Name</div>
              <Input 
                className='w-full' 
                placeholder='Brokerage Name cannot be empty' 
                status={formData.name ? '' : 'error'} 
                value={formData.name} 
                onChange={(e) => _onChange('name', e.target.value)}
              />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Primary Phone</div>
            <MaskedInput 
              mask={'(000) 000 0000'} 
              className='w-full' 
              value={formData.phone}
              onChange={(e: any) => _onChange('phone', e.target.value)}
            />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Secondary Phone</div>
            <MaskedInput 
              mask={'(000) 000 0000'} 
              className='w-full' 
              value={formData.fax}
              onChange={(e: any) => _onChange('fax', e.target.value)}
            />
          </div>
        </div>
      </Content>
    </>
  );
};

export default Broker;