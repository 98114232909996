import { gql } from '@apollo/client';

export const OFFICES_QUERY = gql`
  query Offices {
    offices {
      id
      name
      address
      email
      phone
      fax
      users
    }
  }
`;
