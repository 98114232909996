/* eslint-disable react-hooks/exhaustive-deps */
import { deleteFile, uploadFile } from '@utils/functions';
import { Button, DatePicker, Divider, Input, message, Select, Typography, Upload, UploadFile } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthActions, AuthContext, isUserInAdminRole } from '@shared/context/auth';
import { debounce } from 'lodash';
import MaskedInput from 'antd-mask-input';
import { findOfficesRequest } from '@shared/services/office.service';
import { IUser } from '@interfaces/user.interface';
import dayjs from 'dayjs';
import SocialInput from '@components/SocialInput';
import { Social } from '@shared/utils/enums';
import { updateUserRequest } from '@shared/services/user.service';
import { forgotPasswordRequest } from '@shared/services/auth.service';
import { IOffice } from '@interfaces/office.interface';

const { Title } = Typography;

const Profile = () => {
  const { authState, dispatch } = useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();

  const [offices, setOffices] = useState<IOffice[]>();
  const [formData, setFormData] = useState<IUser>({ ...authState.user });
  const [fileList, setFileList] = useState<UploadFile[]>(authState.user.avatar ? [{ uid: '-1', name: 'avatar.png', status: 'done', url: authState.user.avatar, preview: authState.user.avatar }] : []);

  useEffect(() => {
    const fetchOffices = async () => {
      const result = await findOfficesRequest();
      const mappedOffice = result.response.map((office: any) => ({ value: office.id, label: office.name }))
      setOffices(mappedOffice);
    };

    fetchOffices();
  }, []); 

  const saveChanges = useCallback(
    debounce((values) => {
      updateUserRequest(values)
        .then(() => {
          messageApi.open({ type: 'success', content: 'User information updated correctly' });
          dispatch({ action: AuthActions.SETUSER, data: { user: values }});
        })
        .catch(() => {
          messageApi.open({ type: 'error', content: 'Failed to update information' });
        });
    }, 1000), []
  );

  const _onChange = (name: string, value: any) => {
    setFormData({ ...formData, [name]: value });
    saveChanges({ ...formData, [name]: value });
  };

  const _onImageChange = ({ file }: any) => {
    uploadFile(file, 'image', 'avatar')
      .then(async (res) => { 
        await deleteFile(formData.avatar!);
        setFileList([{ uid: '-1', name: 'avatar.png', status: 'done', url: res.url, preview: res.url }]);
        _onChange('avatar', res.url);
      })
      .catch(() => messageApi.open({ type: 'error', content: 'Failed to upload image' }));
  };

  const _onImageDelete = () => {
    deleteFile(formData.avatar!)
      .then((res) => {
        setFileList([]);
        _onChange('avatar', null);
      })
      .catch(() => messageApi.open({ type: 'error', content: 'Failed to delete image' }));
  };

  const _onAddSocial = () => {
    if (!formData.social) formData.social = [];
    formData.social.push({ platform: Social.INSTAGRAM, username: '' });
    _onChange('social', formData.social);
  }

  const _onUpdateSocial = (index: number, value: { platform: Social, username: string }) => {
    if(formData.social) formData.social[index] = value;
    _onChange('social', formData.social);
  }

  const _onDeleteSocial = (index: number) => {
    formData.social?.splice(index, 1);
    _onChange('social', formData.social);
  }

  const _onPasswordReset = async () => {
    await forgotPasswordRequest(formData.email!)
      .then((resp: any) => {
        messageApi.open({ type: resp.response.type, content: resp.response.message });
      })
  };

  const uploadImgButton = (
    <button style={{ border: 0, background: 'none'}} type='button'>
      <PlusOutlined />
      <div className='mt-1 text-xs'>Upload Photo</div>
    </button>
  );

  return (
    <>
      {contextHolder}
      <Content className='px-4 py-8'>
        <Title level={5} className='mt-[-20px] mb-[-20px]'>Public Profile Information</Title>
        <Divider className='mt-0' />
        <div className='w-full grid grid-cols-12 grid-rows-2 gap-4 mb-8'>
          <div className='row-span-2 col-span-2 relative flex items-center justify-center'>
            <Upload
              name='avatar'
              listType='picture-circle'
              className='avatar-uploader'
              
              fileList={fileList}
              showUploadList={true}
              customRequest={_onImageChange}
              onRemove={_onImageDelete}
            >
              {fileList.length > 0 ? null : uploadImgButton}
            </Upload>
          </div>
          <div className='col-span-4'>
            <div className='text-xs'>Full Name</div>
            <Input 
              className='w-full' 
              placeholder='Full Name cannot be empty' 
              status={formData.fullName ? '' : 'error'} 
              value={formData.fullName} 
              onChange={(e) => _onChange('fullName', e.target.value)}
            />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Birth Date</div>
            <DatePicker 
              className='w-full' 
              value={formData.birthDate ? dayjs(formData.birthDate) : null}
              onChange={(e) => _onChange('birthDate', e ? e.toDate() : null)}
            />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Hire Date</div>
            <DatePicker 
              disabled={!isUserInAdminRole()}
              className='w-full' 
              value={formData.hireDate ? dayjs(formData.hireDate) : null}
              onChange={(e) => _onChange('hireDate', e ? e.toDate() : null)}
            />
          </div>
          <div className='col-span-2 flex items-center justify-end'>
            <Button className='rounded-xl mt-4' onClick={_onPasswordReset} type='primary'>Password Reset</Button>
          </div>
          <div className='col-span-4'>
            <div className='text-xs'>Job Title</div>
            <Input 
              className='w-full' 
              value={formData.jobTitle} 
              onChange={(e) => _onChange('jobTitle', e.target.value)}
            />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Office</div>
            <Select
              disabled={!isUserInAdminRole()}
              showSearch
              placeholder='Select an office'
              optionFilterProp='label'
              className='w-full'
              options={offices}
              value={formData.officeId}
              onChange={(e) => _onChange('officeId', e)}
            />
          </div>
          <div className='col-span-5'></div>
          <div className='col-span-12'>
            <div className='text-xs'>Bio</div>
            <Input.TextArea 
              rows={5} 
              className='w-full'
              value={formData.bio}
              onChange={(e) => _onChange('bio', e.target.value)}
            />
          </div>
          {formData.social?.map((social, idx) =>
            <div className='col-span-2 mt-4'>
              <SocialInput media={social.platform} user={social.username} index={idx} updateFn={_onUpdateSocial} removeFn={_onDeleteSocial}/>
            </div>
          )}
          {(!formData.social || formData.social.length < 6) && 
            <button onClick={_onAddSocial} className='border border-dashed col-span-2 mt-4 flex flex-col items-center bg-control-gray py-6 px-8'>
              <PlusOutlined />
              <div className='mt-4'>Add</div>
              <div className='whitespace-nowrap'>Social Profile</div>
            </button>}
        </div>
        <Title level={5} className='mb-[-20px]'>Contact Information (Private)</Title>
        <Divider className='mt-0' />
        <div className='w-full grid grid-cols-12 grid-rows-1 gap-4 mb-8'>
          <div className='col-span-4'>
            <div className='text-xs'>Email Address</div>
            <Input 
              className='w-full' 
              placeholder='Email cannot be empty' 
              status={formData.email ? '' : 'error'} 
              value={formData.email}
              onChange={(e) => _onChange('email', e.target.value)}
            />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Primary Phone</div>
            <MaskedInput 
              mask={'(000) 000 0000'} 
              className='w-full' 
              value={formData.phone}
              onChange={(e: any) => _onChange('phone', e.target.value)}
            />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Secondary Phone</div>
            <MaskedInput 
              mask={'(000) 000 0000'} 
              className='w-full' 
              value={formData.fax}
              onChange={(e: any) => _onChange('fax', e.target.value)}
            />
          </div>
        </div>
      </Content>
    </>
  );
};

export default Profile;