/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useState } from 'react';
import { createOfficeRequest, deleteOfficeRequest, findOfficesRequest, updateOfficeRequest } from '@services/office.service';
import { IOffice } from 'src/app/interfaces/office.interface';
import EditableTable from '@components/EditableTable';

const Office = () => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<any>(null);
  const [offices, setOffices] = useState<IOffice[]>([]);
  const [officesFull, setOfficesFull] = useState<IOffice[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const result = await findOfficesRequest();
      setOfficesFull(result.response);
      setLoading(false);
    };

    setLoading(true);
    fetchData();
  }, []); 

  useEffect(() => {
    if(searchTerm) {
      const filtered = officesFull.filter((item) => item.name?.toLowerCase().includes(searchTerm.toLowerCase()));
      setOffices(filtered);
    } else {
      setOffices(officesFull);
    }
  }, [officesFull, searchTerm]);

  const columns = [
    { title: 'Name', dataIndex: 'name', width: '18%', editable: true },
    { title: 'Address', dataIndex: 'address', width: '24%', editable: true },
    { title: 'Email', dataIndex: 'email', width: '18%', editable: true, validationRules: [{ type: 'email', message: 'Invalid email format'}] },
    { title: 'Phone', dataIndex: 'phone', width: '14%', editable: true, inputType: 'phone' },
    { title: 'Fax', dataIndex: 'fax', width: '14%', editable: true, inputType: 'phone' },
    { title: 'Agents', dataIndex: 'users', width: '8%', editable: false },
  ];
  
  const _FilterOffice = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const _AddOffice = () => {
    createOfficeRequest()
      .then((resp) => {
        const newData: IOffice = { id: resp.response.id, name: undefined, address: undefined, email: undefined, phone: undefined, fax: undefined };
        setOfficesFull([newData, ...offices]);
        setSelected(newData);
      })
      .catch((err) => {
        console.log('🚀 ~ err', err);
      });
  };

  const _UpdateOffice = async(row: any) => {
    await updateOfficeRequest(row);
  
    const cloned = [...offices];
    const index = cloned.findIndex((item) => row.id === item.id);
    
    if (index > -1) {
      const item = cloned[index];
      cloned.splice(index, 1, { ...item, ...row });
      setOfficesFull([...cloned]);
    }
  };

  const _DeleteOffice = async (id: number) => {
    await deleteOfficeRequest(id);

    const cloned = [...offices];
    const index = cloned.findIndex((item) => id === item.id);

    if (index > -1) {
      cloned.splice(index, 1);
      setOfficesFull([...cloned]);
    } 
  };

  return (
    <Content className='px-4 py-8'>
      <div className='mt-[-20px] flex justify-between mb-4'>
        <Input placeholder='Search' className='rounded-xl w-[25%]' onChange={_FilterOffice}></Input>
        <Button className='rounded-xl' onClick={_AddOffice} type='primary'>Add Office</Button>
      </div>
      <EditableTable 
        source={offices} 
        columns={columns}
        loading={loading}
        selection={selected}
        name='Office' 
        updateFn={_UpdateOffice}
        deleteFn={_DeleteOffice} 
      />
    </Content>
  );
};

export default Office;