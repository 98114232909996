import { gql } from '@apollo/client';

export const REFRESH_USER_MUTATION = gql`
	mutation RefreshUser($input: RefreshUserInput!) {
		refreshUser(refreshUserInput: $input) {
			id
			fullName
			email
			role
			officeId
			phone
		}
	}
`;