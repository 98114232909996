
import NotificationCard from '@components/NotificationCard';
import { INotification } from '@interfaces/notification.interface';
import { findAllNotificationsRequest, markAllAsReadRequest } from '@shared/services/notification.service';
import { Spin, Switch, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useState } from 'react';
import { IoMdNotificationsOff } from 'react-icons/io';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';

const { Title } = Typography;

const Notification = () => {
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [unread, setUnread] = useState(false);

  const fetchNotifications = () => {
    findAllNotificationsRequest()
      .then((res: any) => {
        setNotifications(res.response);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchNotifications();
  }, []); 

  const _onMarkAllRead = () => {
    markAllAsReadRequest();
    setNotifications(notifications.map(notification => ({ ...notification, read: true }) ));
    window.dispatchEvent(new CustomEvent('new_notification', { detail: null }));
  };

  const _onVisibleTrigger = () => {
    setUnread(!unread);
  };

  const _onToggleRead = (id: number) => {
    const updatedNotifications = notifications.map((n: INotification) => n.id === id ? { ...n, read: !n.read } : n);
    setNotifications(updatedNotifications);
    window.dispatchEvent(new CustomEvent('new_notification', { detail: null }));
  };

  const _onDeleteNotification = (id: number) => {
    setNotifications(notifications.filter(notification => notification.id !== id));
    window.dispatchEvent(new CustomEvent('new_notification', { detail: null }));
  };

  return (
      <Content className='bg-white p-4 m-2 flex flex-col h-[98%]'>
        <Title level={5} className='ml-4'>Notifications</Title>
        {loading && (
          <div className='flex flex-col h-full justify-center items-center'>
            <Spin size='large' className='mb-4' />
            <span>Loading Notifications</span>
          </div>
        )}
        {(!loading && notifications.length > 0) && 
          <div className='flex flex-col w-1/2 self-center mt-2'>
            <div className='mb-4 flex justify-between'>
              <div>Show Unread<Switch className='ml-2' value={unread} onChange={_onVisibleTrigger} /></div>
              <p className='flex items-center font-bold text-xs ml-1 mr-2 mt-1 cursor-pointer text-grey hover:text-distinctive-gold' onClick={_onMarkAllRead}>
                <IoCheckmarkDoneSharp size={15} className='mr-1'/>Mark All Read
              </p>
            </div>
            {notifications
              .filter(notification => (unread ? !notification.read : true))
              .map((notification: any) => 
                <NotificationCard notification={notification} onToggle={_onToggleRead} onDelete={_onDeleteNotification}/>
            )} 
          </div>}
          {(!loading && notifications.length === 0) &&
          <div className='flex flex-col w-1/2 self-center mt-2 items-center'>
            <IoMdNotificationsOff size={40} className='text-distinctive-gold mb-4'/>
            <p>No notifications to show</p>
          </div>}
      </Content>
  );
};

export default Notification;
