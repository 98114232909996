import { gql } from '@apollo/client';

export const UPDATE_COMMENT_MUTATION = gql`
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(updateCommentInput: $input) {
      id
      authorId
      postId
      body
      createdAt
    }
  }
`;