import { gql } from '@apollo/client';

export const NOTIFICATIONS_QUERY = gql`
  query Notifications {
    notifications {
      id
      type
      data
      read
      createdAt
      creator {
        id
        fullName
        avatar
      }
    }
  }
`;
