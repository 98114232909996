import dayjs from "dayjs";

export const uploadFile = async (file: string | Blob, type: string, folder: string = '') => {
  const formData = new FormData();
  formData.append('type', type);
  formData.append('folder', folder);
  formData.append('file', file);

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/files/upload`, 
      { 
        method: 'POST', 
        body: formData 
      }
    );

    if (!response.ok) { throw new Error('Failed to upload file'); }

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const deleteFile = async (url: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/files/delete`, 
      { 
        method: 'POST', 
        headers: 
          { 
            'Content-Type': 'application/json'
          }, 
        body: JSON.stringify({ url }) 
      }
    );

    if (!response.ok) { throw new Error('Failed to delete file'); }

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const sendFeedback = async (attachments: string[] | Blob[], user: string, message: string) => {
  const formData = new FormData();
  formData.append('message', message);
  formData.append('user', user);

  attachments.forEach((file) => {
    formData.append('attachments', file);
  });

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/contact/feedback`, 
      { 
        method: 'POST', 
        body: formData 
      }
    );

    if (!response.ok) { throw new Error('Failed to send feedback'); }

    return true;
  } catch (error) {
    console.log(error);
  }
};

export const sendTicket = async (attachments: string[] | Blob[], user: string, message: string) => {
  const formData = new FormData();
  formData.append('message', message);
  formData.append('user', user);

  attachments.forEach((file) => {
    formData.append('attachments', file);
  });

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/contact/support`, 
      { 
        method: 'POST', 
        body: formData 
      }
    );

    if (!response.ok) { throw new Error('Failed to submit ticket'); }

    return true;
  } catch (error) {
    console.log(error);
  }
};

export const formatAsCurrency = (value: number | string) => {
  return value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(typeof value === 'string' ? parseFloat(value) : value) : '';
};

export const formatDaySuffix = (day: number) => {
  if (day % 10 === 1 && day !== 11) return `${day}st`;
  if (day % 10 === 2 && day !== 12) return `${day}nd`;
  if (day % 10 === 3 && day !== 13) return `${day}rd`;
  return `${day}th`;
}

export const formatDatePost = (date: Date) => {
  const now = dayjs();
  const targetDate = dayjs(date);

  const diffInDays = now.diff(targetDate, 'day');
  const diffInHours = now.diff(targetDate, 'hour');

  if (diffInHours < 24) {
    return targetDate.format('h:mm A');
  } else if (diffInDays === 1) {
    return `Yesterday at ${targetDate.format('h:mm A')}`;
  } else {
    const dayWithSuffix = formatDaySuffix(targetDate.date());
    const formattedDate = targetDate.format('ddd MMM');
    const formattedTime = targetDate.format('h:mm A');
    return `${formattedDate} ${dayWithSuffix}, ${formattedTime}`;
  }
}

export const formatDateChat = (localDate: Date, onlyDay = false) => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);

  yesterday.setDate(today.getDate() - 1);
  const messageDate = new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate());

  if (messageDate.getTime() === today.getTime()) {
    return onlyDay ? 'Today' : localDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  } else if (messageDate.getTime() === yesterday.getTime()) {
    return 'Yesterday ' + localDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  } else {
    return localDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
  }
};

export const formatDateNotification = (localDate: Date) => {
  const now = new Date();
  const timeDifference = now.getTime() - localDate.getTime();

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30); // Approximation of a month
  const years = Math.floor(days / 365); // Approximation of a year

  if (days < 1) {
    if (hours < 1) {
      if(minutes < 1) {
        return `${seconds} second${seconds !== 1 ? 's' : ''} ago`
      } else {
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
      }
    }
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else if (days === 1) {
    return 'Yesterday';
  } else if (days < 7) {
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  } else if (weeks === 1) {
    return 'Last week';
  } else if (weeks < 4) {
    return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
  } else if (months === 1) {
    return 'Last month';
  } else if (months < 12) {
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  } else if (years === 1) {
    return 'Last year';
  } else {
    return `${years} year${years !== 1 ? 's' : ''} ago`;
  }
};