import { useContext, useState } from 'react';
import { Avatar, Divider, Layout, Menu, message, Popover } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { IoMdHelpCircle } from 'react-icons/io';
import { BiSolidReport } from 'react-icons/bi';
import { FaLaptop } from 'react-icons/fa';
import { HiMiniChatBubbleOvalLeftEllipsis } from 'react-icons/hi2';
import { FaCalendarAlt } from 'react-icons/fa';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { VscFeedback } from 'react-icons/vsc';
import { FaUserCog } from 'react-icons/fa';
import { MdSupportAgent } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import FeedbackForm from '@components/FeedbackForm';
import { AuthActions, AuthContext, isUserInAdminRole } from '../context/auth';

import logoImg from '@assets/images/logo.png';
import signImg from '@assets/images/sign.png';
import SupportForm from '@components/SupportForm';
import NotificationMenu from '@components/NotificationMenu';

const { Header, Content, Sider } = Layout;

const pathToKeyMap: Record<string, string> = {
	'/admin': '1',
	'/board': '2',
	'/chat': '3',
	'/calendar': '4',
	'/report': '5',
	'/help': '6',
};

const DashboardLayout = ({ children }: any) => {
	const location = useLocation();
	const { authState, dispatch } = useContext(AuthContext);
	const [messageApi, contextHolder] = message.useMessage();

	const [collapsed, setCollapsed] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [openFeedback, setOpenFeedback] = useState(false);
	const [openSupport, setOpenSupport] = useState(false);

	const _onLogoutPress = () => {
		dispatch({ action: AuthActions.LOGOUT, data: { }});
	};

	const sendFeebackFn = () => {
		messageApi.open({ type: 'success', content: 'Thank you for submitting feedback!' });
		setOpenFeedback(false);
	}

	const sendTicketFn = () => {
		messageApi.open({ type: 'success', content: 'Ticket submitted! We will contact you from support' });
		setOpenSupport(false);
	}

	const openSupportFn = (e: any) => {
		e.preventDefault();
		
		setOpenFeedback(false);
		setOpenSupport(true);
	}

	return (
		<>
			{contextHolder}
			<Layout className={'h-screen'}>
				<Layout className='site-layout flex'>
					<Sider
						collapsible
						collapsed={collapsed}
						onCollapse={(collapse) => setCollapsed(collapse)}
						className='bg-distinctive-menu border-r-2 shadow-md py-5 border-distinctive-text'
					>
						<Link to='/' className='logo'>
						{collapsed ? 
							<img src={signImg} alt='logo' className='h-24 justify-self-center pb-5' />
						: 
							<img src={logoImg} alt='logo' className='w-40 justify-self-center pb-5' />
						}
						</Link>
						<Menu 
							mode='inline' 
							className='bg-distinctive-menu'
							selectedKeys={[pathToKeyMap[location.pathname] || '1']}
						>
							{isUserInAdminRole() && 
								<Menu.Item key='1' icon={<FaLaptop size={20} />} className='font-bold'>
									<Link to='/admin'>Admin</Link>
								</Menu.Item>
							}
							<Menu.Item key='2' icon={<FaChalkboardTeacher size={20} />} className='font-bold'>
								<Link to='/board'>Message Board</Link>
							</Menu.Item>
							<Menu.Item key='3' icon={<HiMiniChatBubbleOvalLeftEllipsis size={20} />} className='font-bold'>
								<Link to='/chat'>Private Message</Link>
							</Menu.Item>
							<Menu.Item key='4' icon={<FaCalendarAlt size={20} />} className='font-bold'>
								<Link to='/calendar'>Calendar</Link>
							</Menu.Item>
							<Menu.Item key='5' icon={<BiSolidReport size={20} />} className='font-bold'>
								Report
							</Menu.Item>
							<Menu.Item key='6' icon={<IoMdHelpCircle size={20} />} className='font-bold'>
								Help
							</Menu.Item>
						</Menu>
					</Sider>
					<Layout className='flex flex-col flex-1'>
						<Header className='bg-distinctive-back z-10 flex px-6 justify-end'>
							<div className={'flex gap-4 items-center'}>
								<NotificationMenu />
								<Link to='#' onClick={() => setShowMenu(!showMenu)} className='hover:text-distinctive-gold text-distinctive-text mt-[-6px]'>{authState.user.fullName}</Link>
								<Popover placement='bottomLeft' open={showMenu} content={
									<div className='flex flex-col'>
										<Link to='/account' className='text-distinctive-text flex items-center justify-start' onClick={() => setShowMenu(false)}>
											<FaUserCog className='text-lg mr-2'/>
											<span>My Account</span>
										</Link>
										<Link to='#' className='text-distinctive-text mt-2 flex items-center justify-start' onClick={() => { setOpenSupport(true); setShowMenu(false); }}>
											<MdSupportAgent className='text-lg mr-2'/>
											<span>Contact Support</span>
										</Link>
										<Link to='#' className='text-distinctive-text mt-2 flex items-center justify-start' onClick={() => { setOpenFeedback(true); setShowMenu(false); }}>
											<VscFeedback className='text-lg mr-2'/>
											<span>Leave Suggestion</span>
										</Link>
										<Divider className='my-2'/>
										<Link to='#' className='text-distinctive-text mt-[-2px]' onClick={_onLogoutPress}>
											<LogoutOutlined className='mr-2'/>
											<span>Log Out</span>
										</Link>
									</div>
								}>
									<Avatar src={authState.user.avatar} onClick={() => setShowMenu(!showMenu)} icon={<UserOutlined />} className='cursor-pointer hover:opacity-90 self-center bg-distinctive-text mt-[-5px]'></Avatar>
								</Popover>
							</div>
						</Header>
						<Content className='bg-distinctive-back flex-1 overflow-hidden'>{children}</Content>
						<FeedbackForm openFeedback={openFeedback} setOpenFeedback={setOpenFeedback} sendFeebackFn={sendFeebackFn} openSupportFn={openSupportFn}></FeedbackForm>
						<SupportForm openSupport={openSupport} setOpenSupport={setOpenSupport} sendTicketFn={sendTicketFn}></SupportForm>
					</Layout>
				</Layout>
			</Layout>
		</>
	);
};

export default DashboardLayout;
