import apolloClient from '@config/apolloClient';
import { CREATE_COMMENT_MUTATION } from '@mutations/comment/create.gpl';
import { DELETE_COMMENT_MUTATION } from '@mutations/comment/delete.gpl';
import { UPDATE_COMMENT_MUTATION } from '@mutations/comment/update.gpl';

export const createCommentRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: CREATE_COMMENT_MUTATION,
		variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { response: data.createComment };
};

export const updateCommentRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: UPDATE_COMMENT_MUTATION,
		variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { response: data.updateComment };
};

export const deleteCommentRequest = async (id: number) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: DELETE_COMMENT_MUTATION,
    variables: { id }
	});

	if (errors) return { errors };

	return { response: data.removeComment };
};