import { UserOutlined } from '@ant-design/icons';
import { deleteNotificationRequest, markAsReadRequest, markAsUnreadRequest } from '@shared/services/notification.service';
import { NotificationType } from '@shared/utils/enums';
import { formatDateNotification } from '@shared/utils/functions';
import { Avatar, Popover, Spin } from 'antd';
import { BiSolidCommentDetail } from 'react-icons/bi';
import { FaCommentDots, FaDotCircle } from 'react-icons/fa';
import { IoCheckmarkDoneSharp, IoEyeOff } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { IoEye } from 'react-icons/io5';
import { FaTrashAlt } from 'react-icons/fa';
import { useState } from 'react';

const NotificationCard = ({ notification, onToggle, onDelete }: any) => {
  const navigate = useNavigate();

  const [saving, setSaving] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  
  const _onNotificationClick = () => {
    if(!notification.read) markAsReadRequest(notification.id);

    if(notification.type === NotificationType.COMMENT) {
      navigate('/board', { state: { postId: notification.data.post }});
    } else {
      navigate('/chat', { state: { chatroomId: notification.data.chatroom }});
    }
  };

  const _onToggleRead = async()  => {
    setShowMenu(false);
    setSaving(true);
    
    if(notification.read) {
      await markAsUnreadRequest(notification.id);
    } else {
      await markAsReadRequest(notification.id);
    }

    setSaving(false);
    onToggle(notification.id);
  };

  const _onDeleteNotification = async ()  => {
    setShowMenu(false);
    setSaving(true);

    await deleteNotificationRequest(notification.id);
    setSaving(false);

    onDelete(notification.id);
  };

  return (
    <div className='flex text-xs cursor-pointer hover:bg-slate-200 pr-4 py-1 items-center justifiy-center rounded-xl'>
      <div className='relative' onClick={_onNotificationClick}>
        <Avatar size={45} src={notification.creator.avatar} icon={<UserOutlined />} className='m-2'/>
        {notification.type === NotificationType.COMMENT ?
          <Avatar size={24} icon={<BiSolidCommentDetail />} className='bottom-[4px] right-0 absolute z-10 bg-lime-500'/> : 
          <Avatar size={24} icon={<FaCommentDots />} className='bottom-[4px] right-0 absolute z-10 bg-sky-500'/>
        }
      </div>
      <div className='ml-2 mt-[-10px] flex flex-col w-full h-[40px]'>
        {notification.type === NotificationType.COMMENT ?
          <p><b>{notification.creator.fullName}</b> commented on your post</p> : 
          <p>New private message from <b>{notification.creator.fullName}</b></p>
        }
        <div className={`mb-2 italic ${notification.read ? '' : 'text-distinctive-gold'}`}>{formatDateNotification(new Date(notification.createdAt))}</div>
      </div>
      {notification.read ? 
        <IoCheckmarkDoneSharp size={15} className='ml-4'/> : 
        <FaDotCircle size={15} className='ml-4 text-distinctive-gold'/>
      }
      {saving ? <Spin size='small' className='ml-4' /> : 
      <Popover placement='right' open={showMenu} content={
        <div className='flex flex-col'>
          <div className='text-distinctive-text cursor-pointer flex items-center justify-start mb-1' onClick={_onToggleRead}>
            {notification.read ? <IoEyeOff size={15} className='text-lg mr-2'/> : <IoEye size={15} className='text-lg mr-2'/> }
            <span>{notification.read ? 'Mark as Unread' : 'Mark as Read'}</span>
          </div>
          <div className='text-distinctive-text cursor-pointer flex items-center justify-start' onClick={_onDeleteNotification}>
            <FaTrashAlt size={14} className='text-lg mr-2'/>
            <span>Delete Notification</span>
          </div>
        </div>
      }>
        <HiOutlineDotsHorizontal size={20} className='ml-4' onClick={() => setShowMenu(!showMenu)} />
      </Popover>
      }
    </div>
  );
};

export default NotificationCard;