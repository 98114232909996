/* eslint-disable react-hooks/exhaustive-deps */
import { findUsersRequest } from '@shared/services/user.service';
import { AutoComplete, Avatar, Input, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { UserOutlined } from '@ant-design/icons';

const { Search } = Input;

const ContactSearchInput = ({ selectedContact }: any) => {
  const [inputValue, setInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); 
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const result = await findUsersRequest({ searchTerm });
      setUsers(result.response.map((user: any) => (
        { 
          value: user.id, 
          label: ( 
            <div key={user.id} className='flex items-center justify-start'>
              <Avatar src={user.avatar} icon={<UserOutlined />}/>
              <span className='ml-2'>{user.fullName}</span>
            </div>
          )
        }
      )));
      
      setLoading(false);
    };

    if(searchTerm) {
      fetchData();
    } else {
      setUsers([]);
    }
  }, [searchTerm]); 

  const onTermChange = useCallback(debounce((value: string) => {
    setSearchTerm(value)
  }, 300), []);

  const _onTextChange = (e: any) => {
    setInputValue(e.target.value);
    onTermChange(e.target.value);
  };

  const _onSelect = (value: any) => {
    selectedContact(value);
    setInputValue('');
    setSearchTerm('');
    setUsers([]);
  };

  return (
    <AutoComplete
      options={users}
      onSearch={(value) => setInputValue(value)}
      value={inputValue}
      onSelect={_onSelect}
      className='w-full mb-4'
      notFoundContent={loading ? <div className='flex items-center w-full justify-center'><Spin /></div> : 'No results found'}
    >
      <Search 
        placeholder='Search contact name' 
        loading={loading}
        allowClear
        onChange={_onTextChange}
        className='w-full'
      />
    </AutoComplete>
  );
};

export default ContactSearchInput;