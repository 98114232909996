import { AuthContext } from '@shared/context/auth';
import { formatDateChat, formatDatePost } from '@shared/utils/functions';
import { Upload } from 'antd';
import { useContext } from 'react';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import ChatMessageInput from './ChatMessageInput';

const MessageCard = ({ message }: any) => {
  const { authState } = useContext(AuthContext);

  return (
    <div className={`p-2 m-2 max-w-lg ${ message.authorId === authState.user.id ? 'ml-auto text-right bg-blue-200' : 'mr-auto text-left bg-gray-200' } rounded-lg`} >
      <span>{message.body}</span>
      {message.attachments.length > 0 && (
        <Upload
          fileList={message.attachments.map((file: any, idx: number) => { return { uid: idx, name: file.url, status: 'done', url: file.url }} )}
          listType='picture-card'
          showUploadList={{ showRemoveIcon: false, showDownloadIcon: true }}
        />
      )}
      <div className='mt-2 text-xs flex justify-end'>
        {formatDateChat(new Date(message.createdAt))}
        {message.authorId === authState.user.id && (<IoCheckmarkDoneSharp size={14} title={`${message.read ? 'Read ' + formatDatePost(new Date(message.updatedAt)) : 'Sent ' + formatDatePost(new Date(message.createdAt))}`} className={`mx-1 ${ message.read ? 'text-lime-600' : '' }`}/>)}
      </div>
    </div>
  );
};

const PrivateMessages = ({ chatroom, messages, sendMessage }: any) => {
  const { authState } = useContext(AuthContext);
  
  return (
    <div className='col-span-7 flex flex-col bg-gradient-diagonal h-full overflow-hidden'>
      <div className='flex flex-col-reverse flex-grow px-2 overflow-y-auto overflow-x-hidden'>
        <ChatMessageInput user={authState.user} chatroom={chatroom} sendChatMessage={sendMessage} />
        {[...messages]
          .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          .map((message: any, index: number, array) => {
            const currentDate = new Date(message.createdAt);
            const nextDate = index < array.length - 1 ? new Date(array[index + 1].createdAt) : null;
            const isDifferentDay = !nextDate || currentDate.toDateString() !== nextDate.toDateString();
    
            return (
              <div key={message.id} className='flex flex-col'>
                {isDifferentDay && (
                  <div className='text-center text-black my-4 bg-white p-1 px-4 rounded-lg w-auto mx-auto'>
                    {formatDateChat(currentDate, true)}
                  </div>
                )}
                <MessageCard message={message} />
              </div>
            );
        })}
      </div>
    </div>
  );
};

export default PrivateMessages;