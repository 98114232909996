import { Content } from 'antd/es/layout/layout';
import DashboardStat from '@components/DashboardStat';
import { Button, Card, Input } from 'antd';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { dashboardRequest } from '@shared/services/user.service';
import { formatAsCurrency } from '@shared/utils/functions';

const Dashboard = () => {
	const [hires, setHires] = useState<any[]>([]);
	const [dates, setDates] = useState<any[]>([]);
	const [topAgents, setTopAgents] = useState<any[]>([]);
	const [volume, setVolume] = useState(0);
	const [transactions, setTransactions] = useState(0);
	const [locations, setLocations] = useState(0);
	const [professionals, setProfessionals] = useState(0);
	const [calculatorValue, setCalculatorValue] = useState<string>('');
  const [calculatedFee, setCalculatedFee] = useState<string>('');

	useEffect(() => {
    const fetchData = async () => {
      const result = await dashboardRequest();

			setVolume(result.response.volume);
			setTransactions(result.response.transactions);
			setLocations(result.response.locations);
			setProfessionals(result.response.professionals);
			setTopAgents(result.response.topProducing);

			setHires(result.response.hires.map((item: any) => {
				return {
					name: item.fullName,
					date: '🚀 ' + new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(new Date(item.verifyDate)),
				};
			}));

			setDates([...result.response.birthdays.map((item: any) => {
				return {
					name: item.fullName,
					date: '🎂 ' + new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(new Date(item.birthDate)),
					rawDate: new Date(item.birthDate),
				};
			}),
			...result.response.anniversaries.map((item: any) => {
				return {
					name: item.fullName,
					date: '🍾 ' + new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(new Date(item.hireDate)),
					rawDate: new Date(item.hireDate),
				};
			})]
				.sort((a, b) => a.rawDate.getTime() - b.rawDate.getTime())
				.slice(0, 5));
    };

    fetchData();
  }, []); 
	
	const _onCalculatorChange = (event: any) => {
		const numeric = event.target.value.replace(/[^0-9]/g, '');
		setCalculatorValue(numeric);
	
		if (numeric) {
			const resultFee = 250 + 250 * Math.ceil(parseFloat(numeric) / 200000);
			setCalculatedFee(formatAsCurrency(resultFee));
		} else {
			setCalculatedFee('');
		}
  };

	return (
		<Content className='h-full p-4 flex flex-col h-[98%] overflow-auto'>
			<div className='w-full justify-evenly flex mt-[-16px] h-1/12 mb-8'>
				<DashboardStat stats={volume} name='YTD Sales Volume' money={true}></DashboardStat>
				<DashboardStat stats={transactions} name='YTD Transactions'></DashboardStat>
				<DashboardStat stats={locations} name='Locations'></DashboardStat>
				<DashboardStat stats={professionals} name='Professionals'></DashboardStat>
			</div>
			<div className='w-full h-full flex items-stretch'>
				<div className='w-2/3 h-full'>
					<div className='w-full h-1/2 mb-4 flex'>
						<Card className='w-1/2 h-full' title='Announcements'>
							<p>Card content</p>
						</Card>
						<Card className='ml-4 w-1/2 h-full' title='Calendar'>
							<p>Card content</p>
						</Card>
					</div>
					<div className='w-full h-1/2 flex'>
						<Card className='w-1/2 h-full'>
							<div className='mb-4 mt-[-10px] text-black font-bold text-base'>Top Producing Agents</div>
							{topAgents.map((item: any) => {
								return (
									<div className='flex justify-between'>
										<span>{item.agent}</span>
										<span>{formatAsCurrency(item.earnings)}</span>
									</div>
								);
							})}
						</Card>
						<div className='ml-4 w-1/2 h-full flex flex-col'>
							<Card className='w-full h-1/2' title='Most Agents Referred (YTD)'>
								<p>Card content</p>
							</Card>
							<Card className='w-full mt-4 h-1/2'>
								<div className='mb-4 mt-[-10px] text-black font-bold text-base'>Commission Calculator</div>
								<Input
									placeholder='Purchase Price'
									onChange={_onCalculatorChange}
									value={formatAsCurrency(calculatorValue)}
									className='text-center'
								/>
								<div className='w-full bg-gradient-to-r from-distinctive-light-gold to-distinctive-semi-gold h-10 mt-4 flex items-center justify-center text-black font-bold text-lg'>
									{calculatedFee}
								</div>
							</Card>
						</div>
					</div>
				</div>
				<div className='ml-4 w-1/3 h-full flex flex-col'>
					<Card className='h-1/4 mb-4'>
						<div className='mb-4 mt-[-10px] text-black font-bold text-base'>Birthdays & Anniversaries</div>
						{dates.map((item: any, idx: number) => {
							return (
								<div key={idx} className='flex justify-between'>
									<span>{item.name}</span>
									<span>{item.date}</span>
								</div>
							);
						})}
					</Card>
					<Card className='h-1/4 mb-4'>
						<div className='mb-4 mt-[-10px] text-black font-bold text-base'>Welcome New Agents!</div>
						{hires.map((item: any, idx: number) => {
							return (
								<div key={idx} className='flex justify-between'>
									<span>{item.name}</span>
									<span>{item.date}</span>
								</div>
							);
						})}
					</Card>
					<div className='h-2/4 flex flex-col justify-between mb-8'>
						<Card className='external-button-link bg-zinc-400 flex justify-end'>
							<Link to={'https://app.skyslope.com/'} target='_blank'>
								<Button className='p-2 text-sm rounded-2xl w-40' variant='filled'>Open SkySlope</Button>
							</Link>
						</Card>
						<Card className='external-button-link bg-zinc-400 flex justify-end mt-1'>
							<Link to={'#'}>
								<Button className='p-2 text-sm rounded-2xl w-40' variant='filled'>Open Canva</Button>
							</Link>
						</Card>
						<Card className='external-button-link bg-zinc-400 flex justify-end mt-1'>
							<Link to={'https://tockify.com/distinctionre/pinboard'} target='_blank'>
								<Button className='p-2 text-sm rounded-2xl w-40' variant='filled'>Open Tockify</Button>
							</Link>
						</Card>
						<Card className='external-button-link bg-zinc-400 flex justify-end mt-1'>
							<Link to={'#'}>
								<Button className='p-2 text-sm rounded-2xl w-40'>Open LiveWell</Button>
							</Link>
						</Card>
						<Card className='external-button-link bg-zinc-400 flex justify-end mt-1'>
							<Link to={'#'}>
								<Button className='p-2 text-sm rounded-2xl w-40' variant='filled'>Open Lofty</Button>
							</Link>
						</Card>
					</div>
				</div>
			</div>
		</Content>
	);
};

export default Dashboard;
