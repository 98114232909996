import { gql } from '@apollo/client';

export const MESSAGES_QUERY = gql`
  query Messages($id: Int!) {
    messages(id: $id) {
      id
      authorId
      body
      read
      attachments
      createdAt
      updatedAt
    }
  }
`;
