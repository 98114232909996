import './App.less';
import { AuthProvider } from '@shared/context/auth';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Dashboard from './modules/dashboard';
import ProtectedRoute from '@components/ProtectedRoute';
import Auth from '@modules/auth';
import User from '@modules/user';
import { JSX } from 'react/jsx-runtime';
import Account from '@modules/account';
import Admin from '@modules/admin';
import Calendar from '@modules/calendar';
import MessageBoard from '@modules/board';
import Chatroom from '@modules/chat';
import FirebaseListener from '@components/FirebaseListener';
import Notification from '@modules/notification';

const App = (): JSX.Element => {
	return (
			<AuthProvider>
				<FirebaseListener />
				<Router>
					<Routes>
						<Route element={<ProtectedRoute />}>
							<Route path='/' element={<Dashboard />} />
							<Route path='/users' element={<User />} />
							<Route path='/admin' element={<Admin />} />
							<Route path='/account' element={<Account />} />
							<Route path='/board' element={<MessageBoard />} />
							<Route path='/chat' element={<Chatroom />} />
							<Route path='/calendar' element={<Calendar />} />
							<Route path='/notification' element={<Notification />} />
						</Route>
						<Route path='/auth/*' element={<Auth />} />
					</Routes>
					<ToastContainer />
				</Router>
			</AuthProvider>
  );
}

export default App;
