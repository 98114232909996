import { gql } from '@apollo/client';

export const CREATE_MESSAGE_MUTATION = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(createMessageInput: $input) {
      id
      chatroomId
      authorId
      body
      read
      attachments
      createdAt
    }
  }
`;