import { MessagePayload, onMessage } from '@firebase/messaging';
import { useEffect } from 'react';
import { NotificationType } from '@shared/utils/enums';
import { IFBNotification } from '@interfaces/notification.interface';
import { firebaseMessaging } from '@shared/utils/firebase';

const FirebaseListener = () => {
  useEffect(() => {
    onMessage(firebaseMessaging, (payload: MessagePayload | IFBNotification) => {
      window.dispatchEvent(new CustomEvent('new_notification', { detail: payload?.data }));

      if (payload.notification?.body === NotificationType.MESSAGE) {
        window.dispatchEvent(new CustomEvent('new_message', { detail: payload?.data }));
      }
    });
  }, []);

  return <></>;
};

export default FirebaseListener;
