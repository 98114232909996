
import { gql } from '@apollo/client';

export const DASHBOARD_QUERY = gql`
  query Dashboard {
    dashboard {
      birthdays {
        id
        fullName
        birthDate
      }
      anniversaries {
        id
        fullName
        hireDate
      }
      hires {
        id
        fullName
        verifyDate
      }
      volume
      transactions
      locations
      professionals
      topProducing {
        agent
        earnings
      }
    }
  }
`;
