import { Tabs, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Profile from './Profile';
import Documents from './Documents';

const { Title } = Typography;

const Account = () => {
  return (
    <Content className='bg-white p-4 m-2 h-[98%] overflow-auto'>
      <Title level={5} className='ml-4'>My Account</Title>
      <Tabs type='card' className='mt-4' items={[
        { 
          label: `Profile Info`,
          key: '1',
          children: <Profile />
        },
        { 
          label: `Documents`,
          key: '2',
          children: <Documents />
        },
      ]}>
      </Tabs>
    </Content>
  );
};

export default Account;
