import apolloClient from '@config/apolloClient';
import { LOGIN_MUTATION } from '@mutations/auth/login.gpl';
import { REGISTER_MUTATION } from '@mutations/auth/register.gpl';
import { REFRESH_TOKEN_MUTATION } from '@mutations/auth/refresh.gpl';
import { FORGOT_PASSWORD_MUTATION } from '@mutations/auth/forgot.gpl';
import { VALIDATE_TOKEN_MUTATION } from '@mutations/auth/validate.gpl';
import { RESET_PASSWORD_MUTATION } from '@mutations/auth/reset.gpl';
import { CREATE_PASSWORD_MUTATION } from '@mutations/auth/create.gpl';

export const loginRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: LOGIN_MUTATION,
		variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { ...data.login };
};

export const createPasswordRequest = async (email: string) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: CREATE_PASSWORD_MUTATION,
		variables: { email }
	});

	if (errors) return { errors };

	return { response: data.createPassword };
};

export const forgotPasswordRequest = async (email: string) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: FORGOT_PASSWORD_MUTATION,
		variables: { email }
	});

	if (errors) return { errors };

	return { response: data.forgotPassword };
};

export const refreshTokenRequest = async (jwtRefresh: string) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: REFRESH_TOKEN_MUTATION,
		variables: { input: { jwtRefresh }}
	});

	if (errors) return { errors };

	return { response: data.refreshToken.response };
};

export const validateTokenRequest = async (hash: string, token: string) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: VALIDATE_TOKEN_MUTATION,
		variables: { input: { hash, token }}
	});

	if (errors) return { errors };

	return { response: data.validateToken };
};

export const resetPasswordRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: RESET_PASSWORD_MUTATION,
		variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { ...data.resetPassword };
};

export const registerRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: REGISTER_MUTATION,
		variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return data.register;
};