import apolloClient from '@config/apolloClient';
import { IUser } from '@interfaces/user.interface';
import { CREATE_USER_MUTATION } from '@mutations/user/create.gpl';
import { DELETE_USER_MUTATION } from '@mutations/user/delete.gpl';
import { REFRESH_USER_MUTATION } from '@mutations/user/refresh.gpl';
import { UPDATE_USER_MUTATION } from '@mutations/user/update.gpl';
import { DASHBOARD_QUERY } from '@queries/dashboard.gpl';
import { USERS_QUERY } from '@queries/users.gpl';
import { Role } from '@shared/utils/enums';

export const dashboardRequest = async () => {
	const { data, errors } = await apolloClient.query({
		query: DASHBOARD_QUERY
	});

	if (errors) return { errors };

	return { response: data.dashboard };
};

export const findUsersRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.query({
		query: USERS_QUERY,
		variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { response: data.users.map((user: any) => ({ ...user, role: user.role ? user.role.charAt(0).toUpperCase() + user.role.slice(1).toLowerCase() : null })) };
};

export const createUserRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: CREATE_USER_MUTATION,
		variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { response: data.createUser };
};

export const updateUserRequest = async (payload: IUser) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: UPDATE_USER_MUTATION,
		variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return data.updateUser;
};

export const deleteUserRequest = async (id: number) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: DELETE_USER_MUTATION,
    variables: { id }
	});

	if (errors) return { errors };

	return { response: data.removeUser };
};

export const refreshUserRequest = async (payload: IUser) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: REFRESH_USER_MUTATION,
		variables: { input: { ...payload, role: roleKey(payload.role) }}
	});

	if (errors) return { errors };

	return { ...data.refreshUser, role: payload.role };
};

const roleKey = (value?: string): keyof typeof Role | null => {
  const key = Object.keys(Role).find((key) => Role[key as keyof typeof Role] === value);
  return key ? (key as keyof typeof Role) : null;
};