/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, message, Popconfirm } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useMemo, useState } from 'react';
import { findOfficesRequest } from '@services/office.service';
import EditableTable from '@components/EditableTable';
import ResetPassword from '@assets/icons/reset-password.svg';
import { createUserRequest, deleteUserRequest, findUsersRequest, refreshUserRequest } from '@shared/services/user.service';
import { IUser } from '@interfaces/user.interface';
import { forgotPasswordRequest } from '@shared/services/auth.service';
import { Role } from '@shared/utils/enums';

const User = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<any>(null);
  const [users, setUsers] = useState<IUser[]>([]);
  const [usersFull, setUsersFull] = useState<IUser[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [columns, setColumns] = useState<any>([]);

  useMemo(() => {
    const fetchColumns = async () => {
      const offices = await findOfficesRequest();

      setColumns([
        { title: 'Name', dataIndex: 'fullName', width: '22%', editable: true, defaultSortOrder: 'descend', sorter: (a: any, b: any) => a.fullName.localeCompare(b.fullName) },
        { title: 'Role', dataIndex: 'role', width: '18%', editable: true, inputType: 'select', options: Object.values(Role).map((val) => ({ value: val, label: val })) },
        { title: 'Email', dataIndex: 'email', width: '20%', editable: true, validationRules: [{ required: true, type: 'email', message: 'Invalid email format'}] },
        { title: 'Phone', dataIndex: 'phone', width: '14%', editable: true, inputType: 'phone' },
        { title: 'Office', dataIndex: 'officeId', width: '19%', editable: true, inputType: 'select', options: offices.response.map((office: any) => ({ value: office.id, label: office.name })) },
      ]);
    };

    setLoading(true);
    fetchColumns();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await findUsersRequest({});
      setUsersFull(result.response);
      setLoading(false);
    };

    setLoading(true);
    fetchData();
  }, []); 

  useEffect(() => {
    if(searchTerm) {
      const filtered = usersFull.filter((item) => item.fullName?.toLowerCase().includes(searchTerm.toLowerCase()));
      setUsers(filtered);
    } else {
      setUsers(usersFull);
    }
  }, [usersFull, searchTerm]);
  
  const actions = (record: any) => (
    <Popconfirm title='Send Password Reset Email?' icon={null} okText='Yes' cancelText='No' onConfirm={() => { _ForgotPasswordLink(record.email) }}>
      <img src={ResetPassword} className='mr-4 h-[18px] cursor-pointer' title='Reset Password' alt='Password' />
    </Popconfirm>
  );
  
  const _ForgotPasswordLink = async (email: string) => {
    await forgotPasswordRequest(email)
      .then((resp: any) => {
        messageApi.open({ type: resp.response.type, content: `An email with reset instructions was sent to ${email}` });
      })
  }

  const _FilterUser = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const _AddUser = () => {
    createUserRequest({ fullName: ''})
      .then((resp) => {
        const newData: IUser = { id: resp.response.id, fullName: undefined, phone: undefined, email: undefined, officeId: undefined };
        setUsersFull([newData, ...users]);
        setSelected(newData);
      })
      .catch((err) => {
        console.log('🚀 ~ err', err);
      });
  };

  const _UpdateUser = async(row: any) => {
    await refreshUserRequest(row);
  
    const cloned = [...users];
    const index = cloned.findIndex((item) => row.id === item.id);
    
    if (index > -1) {
      const item = cloned[index];
      cloned.splice(index, 1, { ...item, ...row });
      setUsersFull([...cloned]);
    }
  };

  const _DeleteUser = async (id: number) => {
    await deleteUserRequest(id);

    const cloned = [...users];
    const index = cloned.findIndex((item) => id === item.id);

    if (index > -1) {
      cloned.splice(index, 1);
      setUsersFull([...cloned]);
    } 
  };

  return (
    <>
      {contextHolder}
      <Content className='px-4 py-8 flex flex-col'>
        <div className='mt-[-20px] flex justify-between mb-4'>
          <Input placeholder='Search' className='rounded-xl w-[25%]' onChange={_FilterUser}></Input>
          <Button className='rounded-xl' onClick={_AddUser} type='primary'>Add User</Button>
        </div>
        <div className='flex-grow overflow-y-auto'>
          <EditableTable
            source={users}
            columns={columns}
            actions={actions}
            loading={loading}
            selection={selected}
            name='User'
            updateFn={_UpdateUser}
            deleteFn={_DeleteUser}
          />
        </div>
      </Content>
    </>
  );
};

export default User;