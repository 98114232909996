import { gql } from '@apollo/client';

export const POST_QUERY = gql`
  query Post($id: Int!) {
    post(id: $id) {
      id
      body
      attachments
      author {
        id
        fullName
        avatar
      }
      comments {
        id
        body
        createdAt
        author {
          id
          fullName
          avatar
        }
      }
      createdAt
    }
  }
`;
