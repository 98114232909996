import { gql } from '@apollo/client';

export const NOTIFICATION_QUERY = gql`
  query Notification {
    notification {
      notifications {
        id
        type
        data
        read
        createdAt
        creator {
          id
          fullName
          avatar
        }
      }
      count
    }
  }
`;
