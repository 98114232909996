import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '@shared/context/auth';
import DashboardLayout from '@shared/layouts/Dashboard';

const ProtectedRoute = () => {
  const { authState } = useContext(AuthContext);

  if (authState.loading) {
    return <div>Loading...</div>;
  }

  if (!authState.token && !authState.user) {
    return <Navigate to='/auth/login' replace />;
  }

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

export default ProtectedRoute;
