import { UserOutlined } from '@ant-design/icons';
import { AuthContext } from '@shared/context/auth';
import { formatDateChat } from '@shared/utils/functions';
import { Avatar } from 'antd';
import { useContext } from 'react';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';

const ChatroomCard = ({ room, selected, onSelect }: any) => {
  const { authState } = useContext(AuthContext);
  
  return (
    <div  className={`border flex cursor-pointer items-center w-full ${selected ? 'bg-distinctive-gold' : ''}`} onClick={() => onSelect(room.id)}>
      {room.recipient ? <Avatar size={45} src={room.recipient.avatar} icon={<UserOutlined />} className='m-2'/> : <Avatar size={45} src={authState.user.avatar} icon={<UserOutlined />} className='m-2'/>}
      <div className='flex flex-col flex-grow mr-2'>
        <span className='font-bold'>{room.recipient ? room.recipient.fullName : authState.user.fullName + ' (You)'}</span>
        <div className='flex justify-between w-full mt-1'>
          <span className='text-xs italic truncate max-w-[160px]'>{room.last ? room.last.body : ''}</span>
          <span className='text-xs flex'>
            {room.last && room.last.authorId === authState.user.id && <IoCheckmarkDoneSharp size={14} className={`mr-1 ${room.last.read ? (selected ? 'text-lime-700' : 'text-lime-500' ) : ''}`} />}
            {room.last ? formatDateChat(new Date(room.last.createdAt)) : formatDateChat(new Date(room.createdAt))}
          </span>
        </div>
      </div>
    </div> 
  );
};

export default ChatroomCard;