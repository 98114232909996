import { gql } from '@apollo/client';

export const CREATE_CHATROOM_MUTATION = gql`
  mutation CreateChatroom($input: CreateChatroomInput!) {
    createChatroom(createChatroomInput: $input) {
      id
      users {
        id
        avatar
        fullName
      }
      createdAt
    }
  }
`;