import { Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect } from 'react';

const { Title } = Typography;

const Calendar = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://public.tockify.com/browser/embed.js';
    script.async = true;
    script.setAttribute('data-tockify-script', 'embed');
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  
  // const getListData = (value: Dayjs) => {
  //   let listData: { type: string; content: string }[] = []; 
  //   switch (value.date()) {
  //     case 8:
  //       listData = [
  //         { type: 'warning', content: 'This is warning event.' },
  //         { type: 'success', content: 'This is usual event.' },
  //       ];
  //       break;
  //     case 10:
  //       listData = [
  //         { type: 'warning', content: 'This is warning event.' },
  //         { type: 'success', content: 'This is usual event.' },
  //         { type: 'error', content: 'This is error event.' },
  //       ];
  //       break;
  //     case 15:
  //       listData = [
  //         { type: 'warning', content: 'This is warning event' },
  //         { type: 'success', content: 'This is very long usual event and will expand to 2 lines.' },
  //         { type: 'error', content: 'This is error event 1.' },
  //         { type: 'error', content: 'This is error event 2.' },
  //         { type: 'error', content: 'This is error event 3.' },
  //         { type: 'error', content: 'This is error event 4.' },
  //       ];
  //       break;
  //     default:
  //   }
  //   return listData || [];
  // };

  // const _onCellRender = (value: Dayjs) => {
  //   const listData = getListData(value);
  //   return (
  //     <ul className='events'>
  //       {listData.map((item) => (
  //         <li key={item.content}>
  //           <Badge status={item.type as BadgeProps['status']} text={item.content} />
  //         </li>
  //       ))}
  //     </ul>
  //   );
  // };

  return (
    <Content className='bg-white p-4 m-2 flex flex-col h-[98%]'>
      <Title level={5} className='ml-4'>Calendar</Title>
      <div className='mx-2 rounded-lg overflow-auto h-full'>
        <div data-tockify-component="calendar" data-tockify-calendar="distinctionre"></div>
      </div>
    </Content>
  );
};

export default Calendar;
