import apolloClient from '@config/apolloClient';
import { CREATE_CHATROOM_MUTATION } from '@mutations/chatroom/create.gpl';
import { CHATROOMS_QUERY } from '@queries/chatrooms.gpl';

export const findChatroomsRequest =  async (id: number) => {
	const { data, errors } = await apolloClient.query({
		query: CHATROOMS_QUERY,
    variables: { id },
		fetchPolicy: 'network-only'
	});

	if (errors) return { errors };

	return { response: data.chatrooms };
};

export const createChatroomRequest = async (payload: any) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: CREATE_CHATROOM_MUTATION,
    variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { response: data.createChatroom };
};