import apolloClient from '@config/apolloClient';
import { DELETE_NOTIFICATION_MUTATION } from '@mutations/notification/delete.gpl';
import { MARK_ALL_NOTIFICATION_READ_MUTATION } from '@mutations/notification/mark-all-read.gpl';
import { MARK_NOTIFICATION_READ_MUTATION } from '@mutations/notification/mark-read.gpl';
import { MARK_NOTIFICATION_UNREAD_MUTATION } from '@mutations/notification/mark-unread.gpl';
import { NOTIFICATION_QUERY } from '@queries/notification.gpl';
import { NOTIFICATIONS_QUERY } from '@queries/notifications.gpl';

export const findNotificationsRequest = async () => {
	const { data, errors } = await apolloClient.query({
		query: NOTIFICATION_QUERY,
		fetchPolicy: 'network-only'
	});

	if (errors) return { errors };

	return { response: data.notification };
};

export const findAllNotificationsRequest = async () => {
	const { data, errors } = await apolloClient.query({
		query: NOTIFICATIONS_QUERY,
		fetchPolicy: 'network-only'
	});

	if (errors) return { errors };

	return { response: data.notifications };
};

export const deleteNotificationRequest = async (id: number) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: DELETE_NOTIFICATION_MUTATION,
    variables: { id }
	});

	if (errors) return { errors };

	return { response: data.removeNotification };
};

export const markAsReadRequest = async (id: number) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: MARK_NOTIFICATION_READ_MUTATION,
		variables: { id }
	});

	if (errors) return { errors };

	return { response: data.markRead };
};

export const markAsUnreadRequest = async (id: number) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: MARK_NOTIFICATION_UNREAD_MUTATION,
		variables: { id }
	});

	if (errors) return { errors };

	return { response: data.markUnread };
};

export const markAllAsReadRequest = async () => {
	const { data, errors } = await apolloClient.mutate({
		mutation: MARK_ALL_NOTIFICATION_READ_MUTATION
	});

	if (errors) return { errors };

	return { response: data.markAllRead };
};