import { gql } from '@apollo/client';

export const CHATROOMS_QUERY = gql`
  query Chatrooms($id: Int!) {
    chatrooms(id: $id) {
      id
      users {
        id
        avatar
        fullName
      }
      last {
        id
        body
        read
        attachments
        authorId
        createdAt
      }
      createdAt
    }
  }
`;
