import { gql } from '@apollo/client';

export const CREATE_USER_MUTATION = gql`
	mutation CreateUser($input: CreateUserInput!) {
		createUser(createUserInput: $input) {
      id
      fullName
      email
      role
      phone
      officeId
		}
	}
`;