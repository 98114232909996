/* eslint-disable react-hooks/exhaustive-deps */
import BoardPost from '@components/BoardPost';
import BoardPostInput from '@components/BoardPostInput';
import { IPost } from '@interfaces/post.interface';
import { AuthContext } from '@shared/context/auth';
import { findPostRequest, findPostsRequest } from '@shared/services/post.service';
import { deleteFile } from '@shared/utils/functions';
import { message, Spin, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

const { Title } = Typography;

const MessageBoard = () => {
  const { state } = useLocation();
  const { authState } = useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();
  
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState<IPost[]>([]);
  const [showComments, setShowComments] = useState(false);

  useMemo(() => {
    const fetchLatestPosts = async () => {
      if(state) {
        const statePost = await findPostRequest(Number(state.postId));
        setShowComments(true);
        setPosts([statePost.response]);
      } else {
        const latestPosts = await findPostsRequest({});
        setPosts(latestPosts.response);
      }

      setLoading(false);
    };

    fetchLatestPosts();
  }, []);

  const _onCreatePost = (id: number, body: string, createdAt: string, attachments: any[]) => {
    setPosts([{ id, body, createdAt, attachments, comments: [], author: { id: authState.user.id, fullName: authState.user.fullName, avatar: authState.user.avatar }} , ...posts]);
  };

  const _onDeletePost = (id: number, attachments: any[]) => {
    setPosts([ ...posts.filter((post: any) => post.id !== id)]);
    
    for(const attachment of attachments) {
      deleteFile(attachment.url);
    }

    messageApi.open({ type: 'success', content: 'Board post deleted successfully' });
  };

  return (
    <>
      {contextHolder}
      <Content className='bg-white p-4 m-2 flex flex-col h-[98%] '>
        <Title level={5} className='ml-4'>Board</Title>
        <div className='flex flex-col flex-grow overflow-hidden items-center'>
          <BoardPostInput user={authState.user} createPost={_onCreatePost} />
          {loading && <div className='flex flex-col'><Spin size='large' className='mb-4' /><span>Loading Board posts</span></div>}
          <div className='w-full flex flex-col overflow-y-auto flex-grow'>
            {posts.map((post: any) => (
              <BoardPost key={post.id} post={post} deletePost={_onDeletePost} viewComments={showComments} />
            ))}
          </div>
        </div>
      </Content>
    </>
  );
}

export default MessageBoard;