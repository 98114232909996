import { PaperClipOutlined } from '@ant-design/icons';
import { createMessageRequest } from '@shared/services/message.service';
import { StorageKeys } from '@shared/utils/enums';
import { deleteFile, uploadFile } from '@shared/utils/functions';
import { Button, Input, message, Upload, UploadFile } from 'antd';
import { useEffect, useState } from 'react';
import { IoSendSharp } from 'react-icons/io5';

const { TextArea } = Input;

const ChatMessageInput = ({ user, chatroom, sendChatMessage }: any) => {
  const [messageApi, contextHolder] = message.useMessage();
  
  const [messageText, setMessageText] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);

  useEffect(() => {
    const storedFiles = localStorage.getItem(StorageKeys.CHAT);

    if(storedFiles) {
      setAttachments(JSON.parse(storedFiles));
      setFileList(JSON.parse(storedFiles).map((file: any) => { return { uid: file.url, name: file.url, status: 'done', url: file.url } }));
    }
  }, []);
  
  const _onImageChange = ({ file }: any) => {
    setFileList([ ...fileList, { uid: '-1', name: 'avatar.png', status: 'uploading', url: '' } ]);

    uploadFile(file, 'image', 'message')
      .then(async (res) => { 
        localStorage.setItem(StorageKeys.CHAT, JSON.stringify([ ...attachments, { url: res.url } ]));
        setAttachments([ ...attachments, { url: res.url } ]);
        setFileList([ ...fileList, { uid: res.fileName, name: res.fileName, status: 'done', url: res.url } ]);
      })
      .catch(() => messageApi.open({ type: 'error', content: 'Failed to upload image' }));
  };

  const _onImageDelete = (file: UploadFile) => {
    const removed = fileList?.filter((file) => file.status === 'removed');

    localStorage.setItem(StorageKeys.CHAT, JSON.stringify(removed.map((file) => { return { url: file.url } })));
    setAttachments(removed.map((file) => { return { url: file.url } }));
    setFileList(removed);

    deleteFile(file.url!);
  };

  const _onSendMessage = () => {
    if(!messageText && attachments.length === 0) return;

    setIsSending(true);

    createMessageRequest({ chatroomId: chatroom.id, authorId: user.id, body: messageText, attachments, read: !chatroom.recipient })
      .then((res) => {
        setMessageText('');
        setFileList([]);
        setAttachments([]);
        setIsSending(false);
        sendChatMessage(res.response);
        localStorage.removeItem(StorageKeys.CHAT);

        messageApi.open({ type: 'success', content: 'Message sent successfully' });
      })
  };
  
  return (
    <>
      {contextHolder}
      <div className='flex border-distinctive-gold border-2 w-full items-center rounded-xl w-[90%] self-center bg-white m-2 mb-4'>
        <div className='w-full relative border-r-2 border-distinctive-gold'>
          <TextArea
            variant='borderless'
            className='w-full my-1'
            placeholder='Enter your message here'
            style={{ resize: 'none' }}
            rows={isFocused || messageText ? 5 : 1}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={(e) => setIsFocused(false)}
          />
          <Upload className='border-0 absolute right-[15px] bottom-[6px] cursor-pointer' showUploadList={false} customRequest={_onImageChange}>
            <PaperClipOutlined title='Attach files' className='text-xl text-distinctive-gold'/>
          </Upload>
          {fileList.length > 0 && 
            <Upload
              listType='picture-card'
              className='avatar-uploader m-2'
              fileList={fileList}
              showUploadList={true}
              customRequest={() => {}}
              onRemove={_onImageDelete}
            />
          }
        </div>
        <Button className={`border-0 self-end w-12 my-1 ml-1 ${!messageText && attachments.length === 0 ? 'opacity-30' : ''}`} loading={isSending}>
          <IoSendSharp className='text-xl' title='Create post' onClick={_onSendMessage}/>
        </Button>
      </div>
    </>
  );
};

export default ChatMessageInput;